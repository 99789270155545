<template>
	<div class="markdown" v-html="innerValue" />
</template>

<script>
import { marked } from "marked";
import hljs from "highlight.js";
import DOMPurify from "dompurify";

export default {
	name: "markdownOutput",
	props: {
		markdown: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			innerValue: DOMPurify.sanitize(
				marked.parse(this.markdown, this.markedOptions),
				this.purifyOptions
			),
			markedOptions: {
				highlight: (code, lang, cb) => {
					cb(null, hljs.highlight(code, { language: lang }).value);
				},
			},
			purifyOptions: {
				USE_PROFILES: {
					svg: true,
					svgFilters: true,
					mathMl: true,
					html: false,
				},
				FORBID_TAGS: ["script"],
			},
		};
	},

	watch: {
		markdown(oldValue, newValue) {
			if (oldValue !== newValue) {
				this.innerValue = DOMPurify.sanitize(
					marked.parse(this.markdown, this.markedOptions),
					this.purifyOptions
				);
			}
		},
	},
};
</script>

<style lang="scss">
$line-color-heading: #d0d0d0;
$line-color: #d9d9d9;
$line-height: 1.5px;
$line-margin: 15px;

.markdown {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 15px;

		&:has(+ hr) {
			margin-bottom: 5px;
		}

		& + hr {
			margin: 0 0 20px 0;
			background: $line-color-heading;
			border-color: $line-color-heading;
		}
	}

	hr {
		height: $line-height;
		margin: $line-margin;
		background: $line-color;
		border-color: $line-color;
	}

	p,
	strong,
	em {
		margin: 0;
	}

	blockquote {
		position: relative;
		color: #868686;

		&::before {
			position: absolute;
			content: "";
			height: 100%;
			width: 5px;
			background: #e3e3e3;
			border-radius: 5px;
		}

		p {
			margin-left: 20px;
		}
	}
}
</style>
