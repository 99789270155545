<template>
	<b-nav-item-dropdown
		:text="$t('navbar.loggedIn.dropdown')"
		menu-class="dropdown-menu-end"
	>
		<b-dropdown-item @click="profile"
			>{{ $t("navbar.loggedIn.profile") }}
		</b-dropdown-item>
		<b-dropdown-item @click="requestUserData">
			{{ $t("navbar.loggedIn.data") }}
		</b-dropdown-item>
		<b-dropdown-divider />
		<b-dropdown-item @click="logout"
			>{{ $t("navbar.loggedIn.logout") }}
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
import localStorage from "@/lib/localStorage";
import { api } from "@/lib/api";

export default {
	name: "loggedInDropdown",
	methods: {
		profile: function () {
			this.$router
				.push({
					name: "UserProfile",
					params: { id: this.$store.getters.userId },
				})
				.catch(() => {});
		},

		logout: function () {
			this.$store.dispatch("clearUser");
			localStorage.removeItem("accessToken");
		},

		requestUserData() {
			api("users/me/data", "GET", true).catch(() => {
				this.$error("Couldn't request user data");
			});
		},
	},
};
</script>

<style scoped></style>
