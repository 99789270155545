import { render, staticRenderFns } from "./TagOverview.vue?vue&type=template&id=a6782ebe&scoped=true&"
import script from "./TagOverview.vue?vue&type=script&lang=js&"
export * from "./TagOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6782ebe",
  null
  
)

export default component.exports