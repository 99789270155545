<template>
	<div class="cover">
		<div
			class="cover-img rounded"
			:style="'--cover: url(' + finalSrc + '); --height:' + height"
			v-if="!coverLoading && showCover"
		/>
		<b-skeleton
			class="cover-skeleton"
			:style="'--height:' + height"
			v-else-if="showCover && coverLoading"
		/>
		<div
			class="cover-placeholder rounded"
			:style="'--cover: url(' + placeholder + '); --height:' + height"
			v-else
		/>
	</div>
</template>

<script>
export default {
	name: "bookCover",
	props: {
		src: {
			type: String,
			default: null,
		},
		height: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			placeholderSrc: "https://via.placeholder.com/512x800?text=",
			placeholder: require("@/assets/cover_placeholder.jpg"),
			preSrc: this.src ? this.src : this.placeholder,
			showCover: false,
			coverLoading: false,
			preloadImg: null,
			finalSrc: null,
		};
	},

	methods: {
		loadImg() {
			this.preloadImg = new Image();
			this.preloadImg.addEventListener("load", this.loadedSuccessfully);
			this.preloadImg.addEventListener("error", this.loadFailed);
			this.preloadImg.src = this.preSrc;
		},

		loadedSuccessfully() {
			this.finalSrc = this.preSrc;
			this.coverLoading = false;
		},

		loadFailed(data) {
			this.coverLoading = false;
			this.showCover = false;
			this.$error(data);
		},
	},

	watch: {
		src() {
			if (this.src) {
				this.coverLoading = true;
				this.showCover = true;
				this.preSrc = this.src ? this.src : this.placeholder;
				this.loadImg();
			} else {
				this.showCover = false;
				this.coverLoading = false;
			}
		},
	},

	created() {
		if (this.src) {
			this.coverLoading = true;
			this.showCover = true;
			this.loadImg();
		}
	},

	beforeDestroy() {
		if (this.preloadImg) {
			this.preloadImg.removeEventListener("load", this.loadedSuccessfully);
			this.preloadImg.removeEventListener("error", this.loadFailed);
		}
	},
};
</script>

<style scoped lang="scss">
.cover {
	aspect-ratio: 16 / 25 !important;
	display: inline;
	//height: var(--height, 100%);
	width: auto;
}

.cover-img,
.cover-placeholder {
	aspect-ratio: 16 / 25 !important;
	background: var(--cover);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: var(--height, 100%);
	width: auto;
}

.cover-skeleton {
	aspect-ratio: 16 / 25 !important;
	display: block;
	height: var(--height, 100%);
	width: auto;
	margin: 0;
}
</style>
