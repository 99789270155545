<template>
	<div class="home">
		<div
			class="d-flex flex-column justify-content-center align-items-center position-fixed top-0 start-0 bottom-0 end-0"
			style="background: rgba(82, 82, 82, 0.75)"
			v-if="this.loading"
		>
			<b-spinner style="width: 3rem; height: 3rem"></b-spinner>
			<p class="mt-3">{{ $t("home.loading") }}...</p>
		</div>
		<b-container class="mt-3">
			<div class="d-flex justify-content-center gap-3">
				<book-preview
					v-for="book in this.books"
					:key="book.id"
					:book="book"
					v-on:click="bookDetails(book.id)"
				/>
			</div>
		</b-container>
	</div>
</template>

<script>
import bookPreview from "@/components/bookPreview";
import router from "@/router";
import { api } from "@/lib/api";

export default {
	name: "Home",
	components: {
		bookPreview,
	},

	data() {
		return {
			books: [],
			loading: true,
		};
	},

	methods: {
		getBooks: function () {
			api("books/", "GET").then(async data => {
				this.books = data;
				this.loading = false;
			});
		},

		bookDetails: function (id) {
			router.push({ name: "BookDetails", params: { id } });
		},
	},

	async created() {
		this.getBooks();
	},
};
</script>
