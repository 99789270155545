<template>
	<div class="overview">
		<card class="tiles">
			<div class="tile user-count">
				<h3 v-if="!loading.users">{{ users.length }}</h3>
				<b-skeleton v-else class="h3-skeleton" />
				<span>{{ $t("admin.overview.users.registeredCount") }}</span>
			</div>
			<div class="tile book-count">
				<h3 v-if="!loading.books">{{ books.length }}</h3>
				<b-skeleton v-else class="h3-skeleton" />
				<span>{{
					$tc("admin.overview.books.availableCount", books.length)
				}}</span>
			</div>
		</card>

		<card class="users" v-if="!loading.users">
			<list-user
				v-for="user of users.slice(0, 10)"
				:user="user"
				:key="user.id"
				class="mb-3"
				v-if="users.length > 0"
				@details="toUser(user.id)"
			/>
			<b-button
				variant="primary"
				@click="navTo('AdminUsers')"
				class="nav-btn"
				v-if="users.length > 0"
				>{{ $t("admin.overview.users.more") }}
			</b-button>

			<p v-if="users.length <= 0" class="text-muted fst-italic text-center m-0">
				{{ $t("admin.overview.users.noUsers") }}
			</p>
		</card>

		<card class="users-loading" v-else>
			<list-user-skeleton
				v-for="i in range(0, 10)"
				:key="'user-skeleton-' + i"
				class="mb-3"
			/>
		</card>

		<card class="books" v-if="!loading.books">
			<list-book
				v-for="book of books.slice(0, 10)"
				:book="book"
				:key="book.id"
				class="mb-3"
				v-if="books.length > 0"
			>
				<template #firstButton>
					<b-button variant="primary" class="mb-1 d-block w-100">
						<b-icon icon="pencil-square" />
						{{ $t("admin.overview.books.card.0") }}
					</b-button>
				</template>
				<template #secondButton>
					<b-button variant="outline-danger" class="d-block w-100">
						<b-icon icon="trash" />
						{{ $t("admin.overview.books.card.1") }}
					</b-button>
				</template>
			</list-book>
			<b-button
				variant="primary"
				@click="navTo('AdminBooks')"
				class="nav-btn"
				v-if="books.length > 0"
				>{{ $t("admin.overview.books.more") }}
			</b-button>

			<p v-if="books.length <= 0" class="text-muted fst-italic text-center m-0">
				{{ $t("admin.overview.books.noBooks") }}
			</p>
		</card>

		<card class="books-loading" v-else>
			<list-book-skeleton
				v-for="i in range(0, 10)"
				:key="'book-skeleton-' + i"
				class="mb-3"
			/>
		</card>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import ListUser from "@/components/listUser";
import ListBook from "@/components/listBook";
import Card from "@/components/card";
import ListUserSkeleton from "@/components/listUserSkeleton";
import ListBookSkeleton from "@/components/listBookSkeleton";
import { range } from "@/lib/range";

export default {
	name: "AdminOverview",
	components: {
		Card,
		ListBook,
		ListBookSkeleton,
		ListUser,
		ListUserSkeleton,
	},

	data() {
		return {
			users: [],
			books: [],
			loading: {
				users: true,
				books: true,
			},
		};
	},

	methods: {
		getUsers: function () {
			api("users", "GET")
				.then(data => {
					this.users = data;
					this.loading.users = false;
				})
				.catch(() => {});
		},

		getBooks: function () {
			api("admin/books", "GET", true)
				.then(data => {
					this.books = data;
					this.loading.books = false;
				})
				.catch(() => {});
		},

		navTo: function (name) {
			this.$router.push({ name: name }).catch(() => {});
		},

		toUser: function (id) {
			this.$router
				.push({ name: "AdminUsersDetail", params: { id: id } })
				.catch(() => {});
		},

		range: function (start = 0, end, step = 1) {
			return range(start, end, step);
		},
	},

	created() {
		this.getUsers();
		this.getBooks();
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.tiles {
	display: flex;
	width: 100%;
	gap: 10px;
	padding: 10px 0;
	background: #fff;
	border-radius: 15px;

	.tile {
		min-width: 300px;
		width: 50%;
		max-width: 600px;
		border-right: 1.5px solid $background-color;

		&:last-child {
			border-right: none;
		}
	}
}

.tile {
	h3 {
		font-size: 3rem;
		margin: 10px auto;
		width: fit-content;
	}

	span {
		display: block;
		margin: 0 auto;
		width: fit-content;
	}
}

.user-loading:last-child,
.book-loading:last-child {
	margin-bottom: 0 !important;
}

.h3-skeleton {
	height: 3rem;
	width: 3ch;
	margin: 0 auto;
}

.nav-btn {
	display: inline-block;
	width: fit-content;
	margin: 0 auto;
}

section {
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
}
</style>
