<template>
	<div class="edit-profile">
		<b-container v-if="$store.getters.ready" class="mt-3">
			<b-form v-on:submit.prevent="save">
				<b-row class="mb-3">
					<b-col cols="6">
						<b-input
							v-model="name"
							type="text"
							:placeholder="$t('profile.edit.name')"
							:state="nameValid"
							:formatter="nameFormatter"
						/>
					</b-col>
					<b-col cols="6">
						<div class="input-group">
							<span class="input-group-text">@</span>
							<b-input
								v-model="username"
								type="text"
								:placeholder="$t('profile.edit.username.placeholder')"
								:formatter="usernameFormatter"
								:state="usernameTaken && usernameValid"
								@change="checkUsername"
							/>
						</div>
						<div class="form-text" id="profil-url">
							{{
								$t("profile.edit.username.dynamic", {
									url: urlLocation() + "/user/@" + this.username,
								})
							}}
						</div>
						<b-tooltip target="profil-url">
							{{
								$t("profile.edit.username.permanent", {
									url: urlLocation() + "/user/" + $store.state.user.id,
								})
							}}
						</b-tooltip>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="6">
						<b-input
							v-model="email"
							type="email"
							:placeholder="$t('profile.edit.email.placeholder')"
							:formatter="emailFormatter"
							:state="emailTaken && emailValid"
							@change="checkMail"
						/>
						<div class="form-text">{{ $t("profile.edit.email.notice") }}</div>
					</b-col>
					<b-col cols="6">
						<b-input
							v-model="communityEmail"
							type="email"
							:placeholder="$t('profile.edit.communityEmail.placeholder')"
							:formatter="emailFormatter"
							:state="communityEmailTaken && communityEmailValid"
							@change="checkCommunityMail"
						/>
						<div class="form-text">
							{{ $t("profile.edit.communityEmail.notice") }}
						</div>
					</b-col>
				</b-row>
				<div class="input-group mb-3">
					<span class="input-group-text">http://</span>
					<b-input
						v-model="website"
						type="text"
						:placeholder="$t('profile.edit.website')"
						:state="websiteValid"
					/>
				</div>
				<wysiwyg-editor v-model="biography" class="mb-3" />
				<b-input
					v-model="location"
					:placeholder="$t('profile.edit.location')"
					class="mb-3"
				/>
				<b-button variant="primary" type="submit" :disabled="buttonDisabled">
					<Transition mode="out-in" name="fade-in">
						<p class="d-inline-block mb-0" :key="this.saved.text">
							{{ this.saved.text }}
						</p>
					</Transition>
				</b-button>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";

export default {
	name: "EditProfile",
	components: { WysiwygEditor },
	data() {
		return {
			name: null,
			username: null,
			email: null,
			language: null,
			website: null,
			biography: null,
			communityEmail: null,
			location: null,

			usernameTaken: null,
			emailTaken: null,
			communityEmailTaken: null,

			saved: {
				value: false,
				timeout: null,
				text: this.$t("profile.edit.save.save"),
			},

			saving: false,
		};
	},

	methods: {
		save: async function () {
			if (!this.nameValid && this.nameValid !== null) return;
			if (!this.usernameValid && this.usernameValid !== null) return;
			if (!this.emailValid && this.emailValid !== null) return;
			if (!this.communityEmailValid && this.communityEmailValid !== null)
				return;
			if (!this.websiteValid && this.websiteValid !== null) return;

			if (this.usernameTaken !== null && !this.usernameTaken) return;
			if (this.emailTaken !== null && !this.emailTaken) return;
			if (this.communityEmailTaken !== null && !this.communityEmailTaken)
				return;

			let body = {
				name: this.name,
				username: this.username,
				email: this.email,
				language: this.language,
				website: this.website,
				biography: this.biography,
				communityEmail: this.communityEmail,
				location: this.location,
			};

			api("users", "PATCH", true, body)
				.then(data => {
					this.$store.dispatch("setUser", { data, vm: this });

					clearTimeout(this.saved.timeout);
					this.saved.value = true;
					this.saved.text = this.$t("profile.edit.save.saved");
					this.saved.timeout = setTimeout(() => {
						this.saved.value = false;
						this.saved.text = this.$t("profile.edit.save.save");
					}, 2500);
				})
				.catch(() => {});
		},

		checkUsername: function () {
			api("users/check/" + this.username, "GET")
				.then(data => {
					if (this.username === this.$store.getters.username)
						this.usernameTaken = null;
					else this.usernameTaken = !data.usernameExists;
				})
				.catch(() => {});
		},

		checkMail: function () {
			if (!this.email) return (this.emailTaken = null);
			api("users/check/" + this.email, "GET")
				.then(data => {
					if (this.email === this.$store.state.user.email)
						this.emailTaken = null;
					else this.emailTaken = !data.usernameExists && this.emailValid;
				})
				.catch(() => {});
		},

		checkCommunityMail: function () {
			if (!this.communityEmail) return (this.communityEmailTaken = null);
			api("users/check/" + this.communityEmail, "GET")
				.then(data => {
					if (this.communityEmail === this.$store.state.user.communityEmail)
						this.communityEmailTaken = null;
					else
						this.communityEmailTaken =
							!data.usernameExists && this.communityEmailValid;
				})
				.catch(() => {});
		},

		usernameFormatter: function (value) {
			value = value.replaceAll(this.$store.state.usernameRegex, "");
			return value.toLowerCase();
		},

		nameFormatter: function (value) {
			return value.replaceAll(this.$store.state.nameRegex, "");
		},

		emailFormatter: function (value) {
			value = value.replace(" ", "");
			return value.toLowerCase();
		},

		urlLocation: function () {
			return window.location.origin;
		},
	},

	computed: {
		usernameValid() {
			if (this.username.length < this.$store.state.usernameMinLength)
				return false;
			else if (this.username !== this.$store.state.user.username) return true;
			else return null;
		},

		nameValid() {
			if (this.name.length < this.$store.state.nameMinLength) return false;
			else if (this.name !== this.$store.state.user.name) return true;
			else return null;
		},

		emailValid() {
			if (!this.email.match(this.$store.state.emailRegex)) return false;
			else if (this.email.length <= 0) return false;
			else if (this.email !== this.$store.state.user.email) return true;
			else return null;
		},

		communityEmailValid() {
			if (!this.communityEmail) return null;
			if (!this.communityEmail.match(this.$store.state.emailRegex))
				return false;
			else if (this.communityEmail !== this.$store.state.user.communityEmail)
				return true;
			else return null;
		},

		websiteValid() {
			if (!this.website) return null;
			else if (this.website === this.$store.state.user.website) return null;
			else if (
				this.website.match(this.$store.state.websiteRegex) &&
				this.website.match(this.$store.state.websiteRegex)[0] === this.website
			)
				return true;
			else return false;
		},

		buttonDisabled() {
			return (
				this.usernameValid &&
				this.nameValid &&
				this.emailValid &&
				this.communityEmailValid &&
				this.websiteValid
			);
		},
	},

	created() {
		this.$store.dispatch("refreshStore", { vm: this });

		let unwatch = this.$store.watch(
			state => state.ready,
			() => {
				this.name = this.$store.state.user.name;
				this.username = this.$store.state.user.username;
				this.email = this.$store.state.user.email;
				this.language = this.$store.state.user.language;
				this.website = this.$store.state.user.website;
				this.biography =
					this.$store.state.user.biography || this.$t("profile.edit.biography");
				this.communityEmail = this.$store.state.user.communityEmail;
				this.location = this.$store.state.user.location;
				unwatch();
			}
		);
	},
};
</script>

<style scoped lang="scss">
.fade-in-enter-active {
	transition: opacity 300ms ease-in-out;
}

.fade-in-leave-active {
	transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
	opacity: 0;
}
</style>
