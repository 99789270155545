<template>
	<div class="user" v-if="!loading">
		<div
			class="banner d-flex justify-content-center align-items-center position-relative mb-5"
		>
			<b-img-lazy :src="pickBanner()" class="banner-background" />

			<div class="banner-container">
				<div class="banner-text">
					<div class="banner-text-section">
						<div class="banner-text-inner">
							<div
								class="profile-picture me-3"
								:style="'--color: ' + pickColor()"
							>
								<span>{{ initials() }}</span>
							</div>
							<div>
								<div
									class="d-flex align-items-center justify-content-center mb-2"
								>
									<p class="mb-0 me-2">{{ user.name }}</p>
									<author-badge v-if="user.isAuthor" />
								</div>
								<div
									class="d-flex align-items-center justify-content-center text-muted"
								>
									<p class="mb-0 me-2">@{{ user.username }}</p>
								</div>
							</div>
						</div>
						<b-button
							variant="primary"
							@click="edit"
							class="w-100 mt-2"
							v-if="profileEditable"
							>{{ $t("profile.user.edit") }}
						</b-button>
					</div>
					<div class="banner-text-section">
						<b-row v-if="user.website">
							<b-col cols="2">
								<b-icon icon="globe" />
							</b-col>
							<b-col>{{ user.website }}</b-col>
						</b-row>
						<b-row v-if="user.communityEmail">
							<b-col cols="2">
								<b-icon icon="envelope" />
							</b-col>
							<b-col>{{ user.communityEmail }}</b-col>
						</b-row>
						<b-row v-if="user.debutYear">
							<b-col cols="2">
								<b-icon icon="pencil-square" />
							</b-col>
							<b-col>{{ user.debutYear }}</b-col>
						</b-row>
						<b-row v-if="user.location">
							<b-col cols="2">
								<b-icon icon="house" />
							</b-col>
							<b-col>{{ user.location }}</b-col>
						</b-row>
						<b-row>
							<b-col cols="2">
								<country-flag :country="user.language" />
							</b-col>
							<b-col>{{ user.language.toUpperCase() }}</b-col>
						</b-row>
					</div>
				</div>
				<markdown-output :markdown="user.biography" class="banner-biography" />
			</div>
		</div>

		<div class="featured-books mb-5" v-if="hasFeaturedBooks">
			<BContainer>
				<h2 class="mb-3">{{ $t("profile.user.featuredBooks") }}</h2>
				<div>
					<vue-horizontal>
						<book-preview
							v-for="book of featuredBooks()"
							:book="book"
							:key="book.id"
							class="me-3"
						/>
					</vue-horizontal>
				</div>
			</BContainer>
		</div>

		<div class="books" v-if="hasBooks">
			<BContainer>
				<h2 class="mb-3">{{ $t("profile.user.allBooks") }}</h2>
				<div class="d-flex gap-3">
					<book-preview
						v-for="book of user.books"
						:book="book"
						:key="book.id"
					/>
				</div>
			</BContainer>
		</div>

		<div class="no-books text-center" v-if="!hasBooks && !hasFeaturedBooks">
			<BContainer>
				<p class="text-muted fst-italic">{{ $t("profile.user.noBooks") }}</p>
			</BContainer>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import AuthorBadge from "@/components/authorBadge";
import BookPreview from "@/components/bookPreview";
import CountryFlag from "@/components/countryFlag";
import MarkdownOutput from "@/components/wysiwyg/markdownOutput.vue";

export default {
	name: "UserProfile",
	components: { MarkdownOutput, BookPreview, AuthorBadge, CountryFlag },
	data() {
		return {
			id: this.$route.params.id,
			user: {},
			loading: true,
			banners: [
				"https://cdn.pixabay.com/photo/2020/05/16/16/43/book-5178205_960_720.jpg",
				"https://cdn.pixabay.com/photo/2016/01/20/11/54/book-wall-1151405_960_720.jpg",
				"https://cdn.pixabay.com/photo/2022/11/03/13/07/book-7567437_960_720.jpg",
				"https://cdn.pixabay.com/photo/2015/12/04/09/13/leaves-1076307_960_720.jpg",
			],
			colors: ["#f90", "#890", "#064", "#660027", "#050086"],
		};
	},

	methods: {
		getUser: function () {
			api("users/" + this.id)
				.then(data => {
					this.user = data;
					this.loading = false;
				})
				.catch(() => {});
		},

		pickBanner: function () {
			return this.banners[Math.floor(Math.random() * this.banners.length)];
		},

		pickColor: function () {
			return this.colors[Math.floor(Math.random() * this.colors.length)];
		},

		featuredBooks: function () {
			return this.user.books.filter(book => book.featured);
		},

		initials: function () {
			let fragments = this.user.name.split(" ");
			let initials = "";
			for (const fragment of fragments) {
				initials = initials + fragment.split("")[0];
			}
			return initials;
		},

		edit: function () {
			if (this.user.id !== this.$store.getters.userId) return;
			this.$router.push({ name: "EditProfile" });
		},
	},

	computed: {
		hasFeaturedBooks: function () {
			if (!this.$store.getters.isAuthor) return false;
			return this.user.books.some(book => book.featured);
		},

		hasBooks: function () {
			return this.user.books.length > 0;
		},

		profileEditable: function () {
			return this.user.id === this.$store.getters.userId;
		},
	},

	created() {
		this.getUser();
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.banner {
	min-height: 50vh;
	overflow: hidden;
	padding: 30px;

	.banner-background {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		filter: blur(10px);
	}

	.banner-container {
		background: rgba(236, 236, 236, 0.6);
		width: fit-content;
		max-width: 500px;
		height: fit-content;
		border-radius: 15px;

		.banner-text {
			display: flex;

			.banner-text-inner {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.banner-text-section {
				flex: 1 1;
				display: flex;
				flex-direction: column !important;
				border-right: 1.5px solid darken($gray, 30);
				margin: 10px 0 10px 10px;
				padding-right: 10px;

				&:last-child {
					border-right: none;
					padding: 0;
					margin: 10px;
				}
			}
		}

		.banner-biography {
			padding: 10px;
		}
	}

	.profile-picture {
		box-sizing: border-box;
		aspect-ratio: 1 / 1 !important;
		display: block;
		padding: 5px;
		border-radius: 100%;
		background: var(--color);
		width: fit-content;
		border: 2px #e0e0e0 solid;
		color: #fff;
		user-select: none;
		font-size: 25px;

		span {
			margin: 0;
			padding: 0;
		}
	}

	.book-container {
		display: flex;
		justify-content: center;
	}
}
</style>
