<template>
	<div class="login">
		<div class="login-wrapper p-3 my-3 rounded">
			<b-alert :show="showWrongCredentials" variant="danger"
				>{{ $t("login.errors.wrongCredentials") }}
			</b-alert>
			<b-alert :show="showAccountDisabled" variant="danger"
				>{{ $t("login.errors.accountDisabled") }}
			</b-alert>
			<b-alert :show="showError" variant="danger"
				>{{ $t("login.errors.unknown") }}
			</b-alert>
			<h1 class="mb-3">{{ $t("login.title") }}</h1>
			<b-form v-on:submit.prevent="login">
				<b-input
					v-model="email"
					class="mb-2"
					:placeholder="$t('login.email')"
					type="email"
					required
					:state="emailValid"
				/>
				<b-form-group class="mb-2">
					<b-input
						v-model="password"
						:placeholder="$t('login.password')"
						type="password"
						required
					/>
					<b-link href="#" @click="resetPassword" :disabled="resetLoading"
						>{{ $t("login.reset") }}
						<b-spinner v-if="resetLoading" />
					</b-link>
				</b-form-group>
				<b-button
					variant="primary"
					class="w-100 shadow"
					type="submit"
					:disabled="!formValid || loading"
					>{{ $t("login.button") }}
				</b-button>
			</b-form>
			<hr />
			<p class="text-center">
				{{ $t("login.signup.text") }}
				<b-link @click="signup">{{ $t("login.signup.link") }}</b-link>
			</p>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import localStorage from "@/lib/localStorage";

export default {
	name: "Login",
	data() {
		return {
			returnPath: this.$route.query.to,
			email: null,
			password: null,
			showWrongCredentials: false,
			showError: false,
			showAccountDisabled: false,
			loading: false,
			resetLoading: false,
		};
	},

	methods: {
		login: function () {
			if (!this.formValid || this.loading) return;
			this.loading = true;
			this.showWrongCredentials = false;
			this.showAccountDisabled = false;
			this.showError = false;

			let payload = {
				email: this.email,
				password: this.password,
			};

			api("auth/login", "POST", false, payload)
				.then(data => {
					localStorage.setItem("accessToken", data.access_token);
					return api("users/me", "GET", true);
				})
				.then(user => {
					this.$store.dispatch("setUser", { data: user, vm: this });
					this.loading = false;
					if (this.returnPath)
						return this.$router.push({ path: this.returnPath });
					this.$router.push({
						name: "UserProfile",
						params: { id: `@${user.username}` },
					});
				})
				.catch(err => {
					if (err.message === "Wrong credentials") {
						this.showWrongCredentials = true;
						this.password = null;
						this.loading = false;
						return;
					}
					if (err.message === "Account disabled") {
						this.showAccountDisabled = true;
						this.email = null;
						this.password = null;
						this.loading = false;
						return;
					}
					this.showError = true;
					this.loading = false;
				});
		},

		resetPassword: function () {
			let newLoaction = {
				name: "RequestPasswordReset",
			};

			if (this.email) {
				newLoaction.query = {};
				newLoaction.query.email = this.email;
			}

			this.$router.push(newLoaction);
		},

		signup: function () {
			this.$router.push({ name: "Signup" });
		},
	},

	computed: {
		emailValid() {
			if (typeof this.email !== "string") return null;
			return !!this.email.match(this.$store.state.emailRegex);
		},

		formValid() {
			if (!this.emailValid) return false;
			if (!this.password || !(this.password.length > 0)) return false;
			return true;
		},
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/styles";

.login {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - $header-height);
	width: 100%;
}

.login-wrapper {
	width: 300px;
	height: fit-content;
	background: $gray;
}
</style>
