import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue, { IconsPlugin } from "bootstrap-vue";
import VueHorizontal from "vue-horizontal";
import SweetAlertIcons from "vue-sweetalert-icons";
import DevLogPlugin from "@/plugins/devLog.plugin";
import VueToast from "vue-toast-notification";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/styles/dracula.css";
import "@/styles/styles.scss";
import "vue-select/dist/vue-select.css";
import "@toast-ui/editor/dist/toastui-editor.css";

import {
	faBold,
	faCode,
	faHeading,
	faItalic,
	faListOl,
	faListUl,
	faRedo,
	faStrikethrough,
	faUnderline,
	faUndo,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "./i18n";

library.add(
	faBold,
	faItalic,
	faHeading,
	faListUl,
	faListOl,
	faUndo,
	faRedo,
	faUnderline,
	faStrikethrough,
	faCode
);
//
//
// Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueHorizontal);
Vue.use(SweetAlertIcons);
Vue.use(DevLogPlugin);
Vue.use(VueToast);

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount("#app");
