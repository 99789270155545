<template>
	<b-navbar
		type="light"
		variant="light"
		fixed="top"
		sticky
		class="shadow-sm px-3"
		toggleable="sm"
	>
		<b-navbar-nav>
			<b-nav-item v-on:click.prevent="navigator('Home')"
				>{{ $t("navbar.home") }}
			</b-nav-item>
		</b-navbar-nav>

		<b-navbar-toggle target="nav-collapse" />

		<b-collapse id="nav-collapse" is-nav>
			<b-navbar-nav>
				<b-nav-item-dropdown
					:text="$t('navbar.featured')"
					right
					v-if="featured.length > 0"
				>
					<b-dropdown-item
						class="pb-2"
						v-for="feature in this.featured"
						:key="feature.id"
						@click="navToBook(feature.id)"
						link-class="d-flex align-items-center"
					>
						<book-cover
							:src="feature.coverPath"
							class="me-3 shadow"
							height="75px"
						/>
						{{ feature.title }}
					</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-navbar-nav>

			<b-navbar-nav class="ms-auto">
				<admin-dropdown v-if="$store.getters.isAdmin" />
				<author-dropdown
					v-if="$store.getters.isAuthor || $store.getters.isAdmin"
				/>

				<logged-in-dropdown v-if="$store.state.user.username" />
				<logged-out-dropdown v-if="!$store.state.user.username" />
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>

<script>
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import AuthorDropdown from "@/components/navbar/authorDropdown.vue";
import AdminDropdown from "@/components/navbar/adminDropdown.vue";
import LoggedInDropdown from "@/components/navbar/account/loggedInDropdown.vue";
import LoggedOutDropdown from "@/components/navbar/account/loggedOutDropdown.vue";

export default {
	name: "navbar",
	components: {
		LoggedOutDropdown,
		LoggedInDropdown,
		AdminDropdown,
		AuthorDropdown,
		BookCover,
	},

	data() {
		return {
			featured: [],
		};
	},

	methods: {
		getFeatured: function () {
			api("books/featured", "GET").then(data => {
				this.featured = data;
			});
		},

		navToBook: function (id) {
			this.$router
				.push({ name: "BookDetails", params: { id: id } })
				.catch(() => {});
		},

		navigator: function (page) {
			this.$router.push({ name: page }).catch(() => {});
		},
	},

	created() {
		this.getFeatured();
		this.$store.dispatch("refreshStore", { vm: this });
	},
};
</script>

<style scoped></style>
