<template>
	<div class="book-details">
		<error-message :error="error" />
		<b-form v-on:submit.prevent="save">
			<b-form-input
				class="mb-2"
				v-model="book.title"
				:placeholder="$t('author.edit.details.bookTitle')"
			/>
			<b-form-select
				class="form-select mb-2"
				v-model="book.language"
				:options="languageOptions"
			/>
			<b-form-group
				class="mb-2"
				:description="$t('author.edit.details.coverpath.1')"
			>
				<b-form-input
					type="text"
					v-model="book.coverPath"
					:placeholder="$t('author.edit.details.coverpath.0')"
				/>
			</b-form-group>
			<wysiwyg-editor
				v-model="book.description"
				class="mb-2"
				:placeholder="$t('author.edit.details.description.placeholder')"
			/>
			<div class="form-check form-switch mb-2">
				<label for="continuous">{{
					$t("author.edit.details.continuous")
				}}</label>
				<input
					type="checkbox"
					class="form-check-input"
					v-model="book.continuous"
					id="continuous"
				/>
			</div>
			<div class="form-check form-switch mb-2">
				<label for="public">{{ $t("author.edit.details.public") }}</label>
				<input
					type="checkbox"
					class="form-check-input"
					v-model="book.public"
					id="public"
				/>
			</div>
			<b-button :disabled="loading" variant="primary" type="submit"
				>{{ $t("author.edit.details.save") }}
			</b-button>
			<b-button
				variant="outline-primary"
				class="ms-2"
				:disabled="loading"
				@click="toBook"
				>{{ $t("author.edit.details.toBook") }}
			</b-button>
		</b-form>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";
import ErrorMessage from "@/components/errorMessage.vue";
import { Language } from "@/enums/language.enum";

export default {
	name: "BookDetails",
	components: { ErrorMessage, WysiwygEditor },
	props: ["bookProp"],
	data() {
		return {
			Language,
			id: this.$route.params.id,
			error: null,
			loading: false,
			initialLoad: true,
			book: this.$props["bookProp"],
			languageOptions: [
				{ value: Language.GERMAN, text: "Deutsch" },
				{ value: Language.ENGLISH, text: "English" },
				{ value: Language.FRENCH, text: "Français" },
				{ value: Language.JAPANESE, text: "日本語" },
			],
		};
	},

	methods: {
		save() {
			this.loading = true;

			const body = {
				title: this.book.title,
				language: this.book.language,
				coverPath: this.book.coverPath,
				description: this.book.description,
				continuous: this.book.continuous,
				public: this.book.public,
			};

			this.$log(body);

			api("books/" + this.id, "PATCH", true, body)
				.then(data => {
					this.book = data;
					this.loading = false;
				})
				.catch(err => {
					this.error = err;
					this.loading = false;
					this.$error(err);
				});
		},

		toBook() {
			this.$router.push({ name: "BookDetails", params: { id: this.id } });
		},
	},

	watch: {
		"book.description"() {
			this.$log(this.book.description);
		},
	},
};
</script>

<style scoped></style>
