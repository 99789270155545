<template>
	<b-container class="mt-3" v-if="!loading">
		<b-row>
			<b-col cols="3" class="position-sticky top-0">
				<div class="book-cover">
					<book-cover
						:src="book.coverPath"
						class="shadow mb-3 d-inline-block w-100"
					/>
					<div class="ribbon ribbon-top-right" v-if="book.featured">
						<span class="user-select-none">{{
							$t("books.details.featured")
						}}</span>
					</div>
				</div>
				<div class="author">
					<p @click="navToAuthor(book.author.username)">
						{{ book.author.name }}
						<span class="text-muted username">@{{ book.author.username }}</span>
					</p>
				</div>
				<b-badge
					class="cursor-pointer rounded-pill bg-secondary text-light"
					variant="secondary"
				>
					{{ this.book.genre }}
				</b-badge>
				<br />
				<div class="mt-1 mb-3">
					<b-badge
						class="cursor-pointer rounded-pill bg-secondary text-light me-1"
						variant="secondary"
						v-on:click="tagList(tag)"
						v-for="tag in this.book.tags"
						:key="tag.id"
					>
						{{ tag.name }}
					</b-badge>
				</div>

				<p>
					{{ $t("books.details.language") }}
					<country-flag :country="book.language" />
				</p>

				<div class="continuous-badge mb-3" v-if="book.continuous">
					{{ $t("books.details.continuous.0") }}
				</div>
				<div class="completed-badge mb-3" v-else>
					{{ $t("books.details.continuous.1") }}
				</div>

				<b-button variant="primary" class="w-100 mb-2" pill>
					<b-icon-book class="me-1" />
					{{ $t("books.details.read") }}
				</b-button>
				<b-button
					variant="outline-primary"
					v-on:click="share"
					class="w-100"
					pill
				>
					<b-icon-share class="me-1"></b-icon-share>
					<Transition mode="out-in" name="fade-in">
						<p class="d-inline-block mb-0" :key="this.copied.text">
							{{ this.copied.text }}
						</p>
					</Transition>
				</b-button>
			</b-col>
			<b-col cols="9">
				<div class="book-details mb-5">
					<h2>{{ this.book.title }}</h2>
					<markdown-output :markdown="book.description" />
				</div>

				<div class="chapters">
					<h3>{{ $t("books.details.chapter") }}</h3>
					<chapter-preview
						v-for="(chapter, i) of book.chapters"
						:key="chapter.id"
						:i="i"
						:chapter="chapter"
						class="my-3"
						@click="readChapter(chapter.id)"
					/>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import router from "@/router";
import ChapterPreview from "@/components/chapterPreview.vue";
import CountryFlag from "@/components/countryFlag.vue";
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import MarkdownOutput from "@/components/wysiwyg/markdownOutput.vue";

export default {
	name: "BookDetails",
	components: {
		MarkdownOutput,
		BookCover,
		ChapterPreview,
		CountryFlag,
	},

	data() {
		return {
			id: this.$route.params.id,
			loading: true,
			book: {},
			copied: {
				value: false,
				timeout: null,
				text: this.$t("books.details.share.0"),
			},
		};
	},

	methods: {
		getBook: function () {
			api(`books/${this.id}`, "GET").then(data => {
				this.book = data;
				this.loading = false;
			});
		},

		tagList: function (name) {
			router.push({ name: "TagOverview", params: { name: name } });
		},

		share: async function () {
			navigator.clipboard.writeText(location.toString()).then(() => {
				clearTimeout(this.copied.timeout);
				this.copied.value = true;
				this.copied.text = this.$t("books.details.share.1");
				this.copied.timeout = setTimeout(() => {
					this.copied.value = false;
					this.copied.text = this.$t("books.details.share.0");
				}, 2500);
			});
		},

		readChapter: function (chapterId) {
			this.$router
				.push({
					name: "BookRead",
					params: { bookId: this.id, chapterId: chapterId },
				})
				.catch(() => {});
		},

		navToAuthor: function (username) {
			this.$router
				.push({ name: "UserProfile", params: { id: `@${username}` } })
				.catch(() => {});
		},
	},

	async created() {
		this.getBook();
	},
};
</script>

<style scoped lang="scss">
.fade-in-enter-active {
	transition: opacity 300ms ease-in-out;
}

.fade-in-leave-active {
	transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
	opacity: 0;
}

.book-cover {
	position: relative;
}

/* common */
.ribbon {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
}

.ribbon::before,
.ribbon::after {
	position: absolute;
	z-index: -1;
	content: "";
	display: block;
	border: 5px solid #2980b9;
}

.ribbon span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 15px 0;
	background-color: #3498db;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: #fff;
	font: 700 18px/1 "Lato", sans-serif;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;
}

.ribbon-top-right {
	top: -10px;
	right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
	border-top-color: transparent;
	border-right-color: transparent;
}

.ribbon-top-right::before {
	top: 0;
	left: 0;
}

.ribbon-top-right::after {
	bottom: 0;
	right: 0;
}

.ribbon-top-right span {
	left: -25px;
	top: 30px;
	transform: rotate(45deg);
}

.username {
	cursor: pointer;
	transition: all 0.5s;
}

.username:hover {
	color: #43494f !important;
}

.continuous-badge,
.completed-badge {
	border-radius: 99999999999px;
	padding: 3px;
	width: fit-content;
	height: fit-content;
	font-size: 14px;
}

.continuous-badge {
	$color: #bd5400;
	border: 1px solid $color;
	color: $color;
}

.completed-badge {
	$color: #009abd;
	border: 1px solid $color;
	color: $color;
}
</style>
