<template>
	<div class="books">
		<card v-if="!loading">
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.books.overview.title") }}</h1>
				<b-button
					size="sm"
					variant="outline-secondary"
					:disabled="loading"
					@click="newBook"
				>
					<b-icon icon="plus-circle" />
					{{ $t("admin.books.overview.newBook") }}
				</b-button>
			</div>
			<list-book
				v-for="(book, i) of books"
				:book="book"
				:key="book.id"
				:class="books.length > 1 && i !== books.length - 1 ? 'mb-3' : ''"
			>
				<template #firstButton>
					<b-button variant="primary" class="mb-1 d-block w-100">
						<b-icon icon="pencil-square" />
						{{ $t("admin.books.overview.details") }}
					</b-button>
				</template>
				<template #secondButton>
					<b-button variant="outline-danger" class="d-block w-100">
						<b-icon icon="trash" />
						{{ $t("admin.books.overview.delete") }}
					</b-button>
				</template>
			</list-book>
			<p v-if="books.length <= 0" class="text-muted fst-italic text-center m-0">
				{{ $t("admin.books.overview.noBooks") }}
			</p>
		</card>

		<card v-else>
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.books.overview.title") }}</h1>
				<b-skeleton type="button" />
			</div>
			<list-book-skeleton
				v-for="i in range(1, 10)"
				:key="'book-skeleton-' + i"
				class="mb-3"
			/>
		</card>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import ListBook from "@/components/listBook.vue";
import ListBookSkeleton from "@/components/listBookSkeleton.vue";

import { api } from "@/lib/api";
import { range } from "@/lib/range";

export default {
	name: "AdminBooks",
	components: {
		Card,
		ListBook,
		ListBookSkeleton,
	},
	data() {
		return {
			books: [],
			loading: true,
		};
	},

	methods: {
		getBooks: function () {
			api("admin/books", "GET", true)
				.then(data => {
					this.books = data;
					this.loading = false;
				})
				.catch(() => {});
		},

		newBook() {
			this.$router.push({ name: "NewBook" });
		},

		range: function (start = 0, end, step = 1) {
			return range(start, end, step);
		},
	},

	created() {
		this.getBooks();
	},
};
</script>

<style scoped lang="scss"></style>
