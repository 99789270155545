<template>
	<div class="new-book">
		<div class="container my-3 mx-auto row">
			<div class="col-3">
				<book-cover :src="newBook.coverPath" />
			</div>
			<div class="col-9">
				<b-alert show v-if="error">{{ error.message }}</b-alert>
				<b-form v-on:submit.prevent="saveBook">
					<b-form-input
						class="mb-2"
						v-model="newBook.title"
						:placeholder="$t('author.new.bookTitle')"
					/>
					<b-form-select
						class="form-select mb-2"
						v-model="newBook.language"
						:options="languageOptions"
					/>
					<b-form-group
						class="mb-2"
						:description="$t('author.new.coverpath.1')"
					>
						<b-form-input
							type="text"
							v-model="coverPath"
							:placeholder="$t('author.new.coverpath.0')"
							@change="loadCover"
						/>
					</b-form-group>
					<wysiwyg-editor
						v-model="newBook.description"
						class="mb-2"
						:placeholder="$t('author.new.description.placeholder')"
					/>
					<div class="form-check form-switch mb-2">
						<label for="continuous">{{ $t("author.new.continuous") }}</label>
						<input
							type="checkbox"
							class="form-check-input"
							v-model="newBook.continuous"
							id="continuous"
						/>
					</div>
					<div class="form-check form-switch mb-2">
						<label for="public">{{ $t("author.new.public") }}</label>
						<input
							type="checkbox"
							class="form-check-input"
							v-model="newBook.public"
							id="public"
						/>
					</div>
					<b-button :disabled="loading" variant="primary" type="submit"
						>{{ $t("author.new.save") }}
					</b-button>
				</b-form>
			</div>
		</div>
	</div>
</template>

<script>
import { Language } from "@/enums/language.enum";
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";

export default {
	name: "NewBook",
	components: { BookCover, WysiwygEditor },
	data() {
		return {
			Language: Language,
			newBook: {
				title: null,
				language: Language.GERMAN,
				coverPath: null,
				description: this.$t("author.new.description.default"),
				genre: null,
				tags: [],
				continuous: true,
				public: false,
			},
			languageOptions: [
				{ value: Language.GERMAN, text: "Deutsch" },
				{ value: Language.ENGLISH, text: "English" },
				{ value: Language.FRENCH, text: "Français" },
				{ value: Language.JAPANESE, text: "日本語" },
			],
			loading: false,
			error: null,
			coverPath: null,
		};
	},

	methods: {
		saveBook: function () {
			this.loading = true;
			this.error = null;
			this.loadCover();
			api("books", "POST", true, this.newBook)
				.then(data => {
					this.loading = false;
					this.$router.push({ name: "BookDetails", params: { id: data.id } });
				})
				.catch(err => {
					this.error = err;
					this.loading = false;
				});
		},

		loadCover() {
			this.newBook.coverPath = String(this.coverPath);
		},
	},

	watch: {
		"newBook.description"() {
			this.$log(this.newBook.description);
		},
	},

	computed: {
		coverPathValid() {
			if (!this.newBook.coverPath) return null;
			else if (
				this.newBook.coverPath.match(this.$store.state.websiteRegex) &&
				this.newBook.coverPath.match(this.$store.state.websiteRegex)[0] ===
					this.newBook.coverPath
			)
				return true;
			else return false;
		},
	},
};
</script>

<style scoped lang="scss"></style>
