import Vue from "vue";
import VueRouter from "vue-router";

import {
	pageTitleRouterHandler,
	protectedRouterHandler,
} from "@/maps/route.map";

import Books from "@/views/Home.vue";
import BookDetails from "@/views/Books/BookDetails.vue";
import BookRead from "@/views/Books/BookRead.vue";
import EditProfile from "@/views/Profile/EditProfile.vue";
import UserProfile from "@/views/Profile/UserProfile.vue";
import Login from "@/views/Login/Login.vue";
import RequestReset from "@/views/Login/RequestReset.vue";
import ResetPassword from "@/views/Login/ResetPassword.vue";
import Reactivate from "@/views/Login/Reactivate.vue";
import TagList from "@/views/Tags/TagList.vue";
import TagOverview from "@/views/Tags/TagOverview.vue";
import TagRanking from "@/views/Tags/TagRanking.vue";
import AdminHome from "@/views/Admin/AdminHome.vue";
import AdminOverview from "@/views/Admin/AdminOverview.vue";
import AdminUsers from "@/views/Admin/Users/AdminUsers.vue";
import AdminUserDetail from "@/views/Admin/Users/AdminUserDetail.vue";
import AdminBooks from "@/views/Admin/Books/AdminBooks.vue";
import AdminSettings from "@/views/Admin/AdminSettings.vue";
import NewBook from "@/views/Author/NewBook.vue";
import MyBooks from "@/views/Author/MyBooks.vue";
import NotFound from "@/views/Errors/NotFound.vue";
import EditBook from "@/views/Author/EditBook.vue";
import AdminReactivations from "@/views/Admin/Reactivations/AdminReactivations.vue";
import JSONViewer from "@/views/JSONViewer.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Books,
	},
	{
		path: "/book/:id",
		name: "BookDetails",
		component: BookDetails,
	},
	{
		path: "/book/:bookId/read/:chapterId",
		name: "BookRead",
		component: BookRead,
	},
	{
		path: "/user/edit",
		name: "EditProfile",
		component: EditProfile,
	},
	{
		path: "/user/:id",
		name: "UserProfile",
		component: UserProfile,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/request-password-reset",
		name: "RequestPasswordReset",
		component: RequestReset,
	},
	{
		path: "/reset-password/:token",
		name: "ResetPassword",
		component: ResetPassword,
	},
	{
		path: "/reactivate/:token",
		name: "Reactivate",
		component: Reactivate,
	},
	{
		path: "/json",
		name: "JSONViewer",
		component: JSONViewer,
	},
	{
		path: "/tags/",
		name: "TagList",
		component: TagList,
		children: [
			{
				path: ":name",
				name: "TagOverview",
				component: TagOverview,
			},
			{
				path: "ranking",
				name: "TagRanking",
				component: TagRanking,
			},
		],
	},
	{
		path: "/admin/",
		component: AdminHome,
		children: [
			{
				path: "",
				name: "AdminHome",
				component: AdminOverview,
			},
			{
				path: "users",
				name: "AdminUsers",
				component: AdminUsers,
			},
			{
				path: "users/:id",
				name: "AdminUsersDetail",
				component: AdminUserDetail,
			},
			{
				path: "books",
				name: "AdminBooks",
				component: AdminBooks,
			},
			{
				path: "settings",
				name: "AdminSettings",
				component: AdminSettings,
			},
			{
				path: "reactivations",
				name: "AdminReactivations",
				component: AdminReactivations,
			},
		],
	},
	{
		path: "/author/new-book",
		name: "NewBook",
		component: NewBook,
	},
	{
		path: "/author/my-books",
		name: "MyBooks",
		component: MyBooks,
	},
	{
		path: "/author/edit-book/:id",
		name: "EditBook",
		component: EditBook,
	},
	{
		path: "*",
		name: "404 Seite nicht gefunden",
		component: NotFound,
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

router.beforeEach(pageTitleRouterHandler);
router.beforeEach(protectedRouterHandler);
router.afterEach(() => {
	window.scroll(window.scrollX, 0);
});

export default router;
