<template>
	<div class="book-preview mb-4" v-on:click="$emit('click')">
		<div class="book-cover mb-2">
			<book-cover :src="book.coverPath" class="h-100" />
			<div class="book-cover-overlay rounded">
				<div>
					<b-icon-search variant="light" font-scale="1.5" />
					<p class="text-light">{{ $t("bookPreview.read") }}</p>
				</div>
			</div>
		</div>
		<p class="book-title mb-0">{{ this.book.title }}</p>
		<p
			class="book-author text-muted"
			@click="navToAuthor()"
			v-if="book.author && book.author.name"
		>
			{{ this.book.author.name }}
		</p>
	</div>
</template>

<script>
import BookCover from "@/components/bookCover.vue";

export default {
	name: "bookPreview",
	components: {
		BookCover,
	},
	props: {
		book: {
			type: Object,
			required: true,
			default: () => {},
		},
	},

	methods: {
		navToAuthor: function () {
			this.$router.push({
				name: "UserProfile",
				params: { id: this.book.author.id },
			});
		},
	},
};
</script>

<style scoped lang="scss">
* {
	cursor: pointer;
}

.book-preview {
	height: 200px;
}

.book-cover {
	aspect-ratio: 16 / 25;
	height: 100%;
	max-height: 300px;
	width: fit-content;
	position: relative;

	img {
		height: 100%;
		max-height: 300px;
		position: relative;
	}

	.book-cover-overlay {
		aspect-ratio: 16/25;
		transition: all 0.2s ease-in-out;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(88, 88, 88, 0.4);
		backdrop-filter: blur(0px);
		opacity: 0;

		div {
			text-align: center;
			transition: all 0.2s ease-in-out;
			transform: translateY(30px) scale(0.6);
		}
	}

	&:hover .book-cover-overlay {
		backdrop-filter: blur(5px);
		opacity: 1;

		div {
			transform: translateY(0) scale(1);
		}
	}
}
</style>
