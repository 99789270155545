<template>
	<div class="chapter">
		<b-container class="mt-3">
			<b-row>
				<b-col cols="3">
					<book-cover :src="chapter.book.coverPath" />
					<h3>{{ chapter.book.title }}</h3>
					<p>
						{{ chapter.book.author.name }}
						<span class="text-muted fst-italic"
							>@{{ chapter.book.author.username }}</span
						>
					</p>
				</b-col>
				<b-col cols="9">
					<h2 class="text-center mb-4">{{ chapter.name }}</h2>
					<markdown-output :markdown="chapter.text" class="mb-5" />

					<div v-if="continuous">
						<b-button
							variant="outline-primary"
							class="d-block mx-auto"
							@click="nextChapterNav"
							:disabled="!nextChapter"
						>
							{{ $t("books.read.nextChapter") }}
						</b-button>
						<p v-if="!nextChapter" class="text-center mt-2">
							{{ $t("books.read.notAvailable") }}
						</p>
					</div>

					<div v-else-if="!continuous">
						<b-button
							variant="outline-primary"
							class="d-block mx-auto"
							@click="nextChapter"
							v-if="nextChapter"
						>
							{{ $t("books.read.nextChapter") }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import MarkdownOutput from "@/components/wysiwyg/markdownOutput.vue";

export default {
	name: "BookRead",
	components: { MarkdownOutput, BookCover },
	data() {
		return {
			bookId: this.$route.params.bookId,
			chapterId: this.$route.params.chapterId,
			chapter: {},
		};
	},

	methods: {
		getChapter() {
			api(`books/${this.bookId}/chapters/${this.chapterId}`, "GET")
				.then(data => {
					this.chapter = data;
					this.$log(data);
				})
				.catch(err => {
					this.$error(err);
				});
		},

		nextChapterNav() {
			this.$router.push({
				name: "BookRead",
				params: { bookId: this.bookId, chapterId: this.nextChapter },
			});
		},
	},

	computed: {
		nextChapter() {
			return this.chapter.nextChapterId;
		},

		continuous() {
			return this.chapter.book.continuous;
		},
	},

	created() {
		this.getChapter();
	},
};
</script>

<style scoped></style>
