import localStorage from "@/lib/localStorage";

const SERVER_HOST = process.env.VUE_APP_API_HOST;

export function api(
	route,
	method = "GET",
	useAuth = false,
	body = null,
	fetchOptions
) {
	return new Promise((resolve, reject) => {
		let options = {
			...fetchOptions,
			method: method,
			headers: {
				"Content-Type": "application/json",
			},
		};

		if (body) {
			options.body = JSON.stringify(body);
		}

		if (useAuth) {
			let accessToken = localStorage.getItem("accessToken");
			options.headers["Authorization"] = "Bearer " + accessToken;
		}

		fetch(`${SERVER_HOST}/${route}`, options)
			.then(async response => {
				if (response.status === 200 || response.status === 201) {
					try {
						const data = await response.json();
						resolve(data);
					} catch (e) {
						console.error(`${method.toUpperCase()} /${route}: `, e);
						reject(e);
					}
				} else {
					const data = await response.json();
					console.error(`${method.toUpperCase()} /${route}: `, data);
					reject(data);
				}
			})
			.catch(error => {
				if (error.name === "AbortError") return reject();
				console.error(`${method.toUpperCase()} /${route}: `, error);
				reject(error);
			});
	});
}
