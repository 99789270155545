<template>
	<div class="book">
		<div class="d-flex align-items-center">
			<book-cover :src="book.coverPath" class="me-3 shadow cover" />
			<div>
				<p class="m-0">
					{{ book.title }}
					<country-flag :country="book.language" class="d-inline-block" />
				</p>
				<p class="m-0 text-muted" v-if="book.author">
					{{ book.author.name }}
					<span class="fst-italic"
						>@{{ book.author.username.toLowerCase() }}</span
					>
				</p>
			</div>
		</div>
		<div>
			<slot name="firstButton" />
			<slot name="secondButton" />
		</div>
	</div>
</template>

<script>
import CountryFlag from "@/components/countryFlag";
import BookCover from "@/components/bookCover.vue";

export default {
	name: "listBook",
	components: {
		BookCover,
		CountryFlag,
	},

	props: {
		book: {
			type: Object,
			required: true,
			default: () => {},
		},
	},
};
</script>

<style scoped lang="scss">
.book {
	height: 100px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.cover {
		height: 100px;
	}
}
</style>
