<template>
	<div class="book-chapters">
		<chapter-preview
			v-for="(chapter, i) of chapters"
			:i="i"
			:chapter="chapter"
			:key="chapter.id"
		/>
	</div>
</template>

<script>
import ChapterPreview from "@/components/chapterPreview.vue";

export default {
	name: "BookChapters",
	components: { ChapterPreview },
	props: ["chapters"],
};
</script>

<style scoped></style>
