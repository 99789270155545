<template>
	<div id="app">
		<navbar />
		<router-view />
	</div>
</template>

<script>
import navbar from "@/components/navbar/navbar.vue";

export default {
	name: "App",
	components: {
		navbar,
	},
	created() {
		this.$store.dispatch("updateSettings");
	},
	metaInfo: {
		title: "Dealcraft Books",
	},
};
</script>

<style lang="scss">
.cursor-pointer {
	cursor: pointer;
}
</style>
