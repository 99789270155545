import Vue from "vue";
import Vuex from "vuex";
import { api } from "@/lib/api";
import { UserRole } from "@/enums/user.roles";
import router from "@/router";
import { isProtectedRoute } from "@/maps/route.map";
import localStorage from "@/lib/localStorage";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {},
		emailRegex: /^[^.\-_][a-zA-Z0-9._-]{2,}@[a-z0-9.-]+\.[a-z]{2,}$/gm,
		usernameRegex: /[^a-zA-Z0-9.\-_]+/gm,
		nameRegex: /[^a-zA-Z\- ]+/gm,
		websiteRegex:
			/^[^.\-_][a-zA-Z0-9.-]+\.[a-z]{2,}(\/?[a-zA-Z0-9/]*[a-zA-Z0-9]+)?$/gm,
		coverPathRegex:
			/^[^.\-_][a-zA-Z0-9.-]+\.[a-z]{2,}(\/?[a-zA-Z0-9./]*[a-zA-Z0-9.]+)?[^.\-_/]$/gm,
		passwordRegex: /^[a-zA-Z0-9.\-+#$?]{12,}$/gm,
		usernameMinLength: 3,
		nameMinLength: 3,
		ready: false,
		abort: null,
		settings: [],
	},
	getters: {
		username(state) {
			return state.user.username;
		},
		userId(state) {
			return state.user.id;
		},
		ready(state) {
			return state.ready;
		},
		isAuthor(state) {
			if (state.user.roles) return state.user.roles.includes(UserRole.AUTHOR);
			return false;
		},
		isAdmin(state) {
			if (state.user.roles)
				return state.user.username && state.user.roles.includes(UserRole.ADMIN);
			return false;
		},
		userRoles(state) {
			if (state.user.roles) return state.user.roles;
			return [];
		},
		signupEnabled(state) {
			if (!state.settings.filter(setting => setting.key === "enable_signup")[0])
				return false;
			return state.settings.filter(
				setting => setting.key === "enable_signup"
			)[0].value;
		},
	},
	mutations: {
		SET_USER(state, payload) {
			state.user = payload;
		},

		CLEAR_USER(state) {
			state.user = {};
		},

		SET_READY_STATUS(state, status) {
			state.ready = status;
		},

		SET_SETTINGS(state, payload) {
			state.settings = payload;
		},
	},
	actions: {
		setUser(context, { data, vm }) {
			context.commit("SET_USER", data);
			vm.$i18n.locale = data.language;
		},

		clearUser(context) {
			context.commit("CLEAR_USER");
			if (isProtectedRoute(router.currentRoute.name))
				router.push({ name: "Home" }).catch(() => {});
		},

		updateSettings(context) {
			api("settings/public", "GET")
				.then(data => {
					context.commit("SET_SETTINGS", data);
				})
				.catch(err => {
					this._vm.$log("Failed to load settings", err);
				});
		},

		refreshStore(context, { vm }) {
			if (context.state.abort) {
				context.state.abort.abort();
			}
			context.state.abort = new AbortController();

			if (!localStorage.getItem("accessToken")) return;
			context.commit("SET_READY_STATUS", false);
			api("users/me", "GET", true, null, { signal: context.state.abort.signal })
				.then(data => {
					context.commit("SET_USER", data);
					vm.$i18n.locale = data.language;
					context.commit("SET_READY_STATUS", true);
				})
				.catch(err => {
					if (err.statusCode === 401) context.dispatch("clearUser");
				});
		},
	},
	modules: {},
});
