<template>
	<div class="administration">
		<div class="banner">
			<h1>{{ $t("admin.home.banner", { user: $store.state.user.name }) }}</h1>
		</div>

		<div class="content container">
			<div class="row w-100 mx-auto gap-3">
				<b-nav pills vertical class="col-12 col-md-2 nav">
					<b-nav-item to="/admin" exact exact-active-class="active">
						{{ $t("admin.home.nav.overview") }}
					</b-nav-item>
					<b-nav-item to="/admin/users" exact exact-active-class="active">
						{{ $t("admin.home.nav.users") }}
					</b-nav-item>
					<b-nav-item to="/admin/books" exact exact-active-class="active">
						{{ $t("admin.home.nav.books") }}
					</b-nav-item>
					<b-nav-item to="/admin/settings" exact exact-active-class="active">
						{{ $t("admin.home.nav.settings") }}
					</b-nav-item>
					<b-nav-item
						to="/admin/reactivations"
						exact
						exact-active-class="active"
					>
						{{ $t("admin.home.nav.reactivations") }}
					</b-nav-item>
				</b-nav>

				<router-view class="col-12 col-md-9 flex-grow-1" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AdminHome",
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";

* {
	transition: all 0.3s;
}

.banner {
	width: 100%;
	min-height: 35vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	box-shadow: 0 2px 20px 0 darken($background-color, 10);
}

.content {
	margin-top: 40px;
	margin-bottom: 40px;
	background: $background-color;
	border-radius: 15px;
	padding-top: 30px;
	padding-bottom: 30px;
	position: sticky;
	overflow: hidden;
}

.nav {
	position: sticky !important;
	top: 0;
	align-self: flex-start;
}

@media (max-width: 767.98px) {
	.nav {
		position: static !important;
	}
}
</style>
