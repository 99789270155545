<template>
	<div class="user">
		<div class="d-flex">
			<div class="profile-picture me-3" :style="'--color: ' + pickColor()">
				<span>{{ initials() }}</span>
			</div>
			<div>
				<p class="m-0">{{ user.name }}</p>
				<p class="m-0 text-muted fst-italic">
					@{{ user.username.toLowerCase() }}
				</p>
			</div>
		</div>
		<div>
			<b-button
				variant="primary"
				class="mb-1 d-block w-100"
				@click="$emit('details', user.id)"
			>
				<b-icon icon="pencil-square" />
				{{ $t("listUser.details") }}
			</b-button>
			<b-button
				variant="outline-danger"
				class="d-block w-100"
				v-if="$store.getters.userId !== user.id"
				@click="$emit('delete', user.id)"
				:disabled="loading"
			>
				<b-icon icon="trash" />
				{{ $t("listUser.delete") }}
			</b-button>
		</div>
	</div>
</template>

<script>
export default {
	name: "listUser",
	props: {
		user: {
			type: Object,
			required: true,
			default: () => {
				return {
					id: "0000-0000-0000-0000",
					name: "Max Mustermann",
					username: "MaxMustermann",
					website: "mustermann.de",
					communityEmail: null,
				};
			},
		},
		loading: {
			type: Boolean,
		},
	},

	data() {
		return {
			colors: ["#f90", "#890", "#064", "#660027", "#050086"],
		};
	},

	methods: {
		initials: function () {
			let fragments = this.user.name.split(" ");
			let initials = "";
			for (const fragment of fragments) {
				initials = initials + fragment.split("")[0];
			}
			return initials;
		},

		pickColor: function () {
			return this.colors[Math.floor(Math.random() * this.colors.length)];
		},
	},
};
</script>

<style scoped lang="scss">
.profile-picture {
	box-sizing: border-box;
	aspect-ratio: 1 / 1 !important;
	display: block;
	width: fit-content;
	padding: 5px;
	border-radius: 100%;
	background: var(--color);
	border: 2px #e0e0e0 solid;
	color: #fff;
	user-select: none;
	font-size: 25px;

	span {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}
}

.user {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
