<template>
	<Card v-if="!initialLoad">
		<error-message :error="error" />
		<h1 class="mb-4">{{ user.name }}</h1>
		<div>
			<b-form v-on:submit.prevent="saveUser">
				<div class="input-group mb-2">
					<b-form-input
						type="text"
						v-model="user.id"
						:placeholder="$t('admin.users.details.id')"
						disabled
					/>
					<b-button type="button" variant="outline-secondary" @click="copyId"
						>{{ $t("admin.users.details.copyId") }}
					</b-button>
				</div>
				<b-row class="mb-2">
					<b-col>
						<b-form-input
							type="text"
							v-model="user.name"
							:placeholder="$t('admin.users.details.name')"
							:disabled="loading"
						/>
					</b-col>
					<b-col>
						<b-form-input
							type="text"
							v-model="user.username"
							:placeholder="$t('admin.users.details.username')"
							:disabled="loading"
						/>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col>
						<b-form-input
							type="email"
							v-model="user.email"
							:placeholder="$t('admin.users.details.email')"
							:disabled="loading"
						/>
					</b-col>
					<b-col>
						<b-form-input
							type="email"
							v-model="user.communityEmail"
							:placeholder="$t('admin.users.details.communityEmail')"
							:disabled="loading"
						/>
					</b-col>
				</b-row>

				<b-row class="mb-2">
					<b-col>
						<vue-select
							:options="roleOptions"
							v-model="userRole"
							multiple
							:disabled="loading"
						/>
					</b-col>
					<b-col>
						<vue-select
							:options="languageOptions"
							v-model="userLanguage"
							:disabled="loading"
						/>
					</b-col>
				</b-row>

				<b-form-input
					class="mb-2"
					type="text"
					v-model="user.website"
					:placeholder="$t('admin.users.details.website')"
					:disabled="loading"
				/>
				<b-form-input
					class="mb-2"
					type="text"
					v-model="user.location"
					:placeholder="$t('admin.users.details.location')"
					:disabled="loading"
				/>
				<b-form-input
					class="mb-2"
					type="number"
					step="1"
					v-model="user.debutYear"
					:placeholder="$t('admin.users.details.debutYear')"
					:disabled="loading || !isAuthor"
				/>

				<wysiwyg-editor v-model="user.biography" class="mb-4" />

				<div class="mb-2">
					<p class="mb-1">
						{{
							$t("admin.users.details.failedLogins", {
								n: user.failedLogins,
							})
						}}
					</p>
					<b-button
						type="button"
						@click="resetLogins"
						variant="secondary"
						:disabled="loading"
						>{{ $t("admin.users.details.resetLogins") }}
					</b-button>
				</div>
				<b-button
					type="button"
					@click="resetPassword"
					variant="secondary"
					class="mb-2"
					:disabled="resettingPassword"
					>{{ $t("admin.users.details.resetPassword") }}
				</b-button>
				<br />
				<b-button
					type="button"
					class="mb-4"
					@click="toggleActive"
					:variant="isActive ? 'danger' : 'success'"
					:disabled="loading || isSelf"
					>{{
						isActive
							? $t("admin.users.details.activate.0")
							: $t("admin.users.details.activate.1")
					}}
				</b-button>
				<br />
				<b-button type="submit" variant="primary" :disabled="loading"
					>{{ $t("admin.users.details.save") }}
				</b-button>
			</b-form>
		</div>

		<hr v-if="hasBooks" />

		<div v-if="hasBooks">
			<h2>{{ $t("admin.users.details.books") }}</h2>
			<div>
				<list-book
					v-for="book of user.books"
					:book="book"
					:key="book.id"
					class="mb-3"
				/>
			</div>
		</div>
	</Card>

	<Card v-else>
		<b-skeleton type="text" class="heading-skeleton mb-4" />

		<div class="input-group mb-2 d-flex">
			<b-skeleton type="input" class="flex-grow-1" />
			<b-skeleton type="button" />
		</div>

		<b-row class="mb-2">
			<b-col>
				<b-skeleton type="input" />
			</b-col>
			<b-col>
				<b-skeleton type="input" />
			</b-col>
		</b-row>
		<b-row class="mb-2">
			<b-col>
				<b-skeleton type="input" />
			</b-col>
			<b-col>
				<b-skeleton type="input" />
			</b-col>
		</b-row>

		<b-row class="mb-2">
			<b-col>
				<b-skeleton type="input" />
			</b-col>
			<b-col>
				<b-skeleton type="input" />
			</b-col>
		</b-row>

		<b-skeleton class="mb-2" type="input" />
		<b-skeleton class="mb-2" type="input" />
		<b-skeleton class="mb-2" type="input" />

		<b-skeleton class="mb-4" rows="5" />

		<div class="mb-2">
			<b-skeleton class="mb-1 login-attempts-skeleton" />
			<b-skeleton type="button" class="reset-logins-skeleton" />
		</div>
		<b-skeleton type="button" class="mb-2 activate-skeleton" />
		<br />
		<b-skeleton type="button" class="mb-4" />
		<br />
		<b-skeleton type="button" class="submit-skeleton" />
	</Card>
</template>

<script>
import Card from "@/components/card.vue";
import VueSelect from "vue-select";
import { api } from "@/lib/api";
import { Language } from "@/enums/language.enum";
import { UserRole } from "@/enums/user.roles";
import ListBook from "@/components/listBook.vue";
import ErrorMessage from "@/components/errorMessage.vue";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";

export default {
	name: "UserDetail",
	components: { WysiwygEditor, ErrorMessage, ListBook, Card, VueSelect },
	data() {
		return {
			Language: Language,
			UserRole: UserRole,
			id: this.$route.params.id,
			loading: false,
			initialLoad: true,
			resettingPassword: false,
			error: null,
			user: {},
			roleOptions: [
				{ label: this.$t("admin.users.details.role.0"), value: UserRole.USER },
				{
					label: this.$t("admin.users.details.role.1"),
					value: UserRole.AUTHOR,
				},
				{ label: this.$t("admin.users.details.role.2"), value: UserRole.ADMIN },
			],
			languageOptions: [
				{ label: "Deutsch", value: Language.GERMAN },
				{ label: "English", value: Language.ENGLISH },
				{ label: "Français", value: Language.FRENCH },
				{ label: "日本語", value: Language.JAPANESE },
			],
			userLanguage: null,
			userRole: null,
		};
	},

	methods: {
		getUser: function () {
			this.error = null;
			api("users/" + this.id, "GET", true)
				.then(data => {
					this.user = data;
					this.userRole = this.roleOptions.filter(option =>
						data.roles.includes(option.value)
					);
					this.userLanguage = this.languageOptions.filter(
						option => data.language === option.value
					)[0];
					if (!data.biography || data.biography.length < 1) {
						this.user.biography = this.$t("admin.users.details.biography");
					}
					this.initialLoad = false;
				})
				.catch(err => {
					this.initialLoad = false;
					this.error = err;
					this.scrollToTop();
				});
		},

		saveUser: function () {
			this.loading = true;
			this.error = null;

			const body = {
				...this.user,
				debutYear: parseInt(this.user.debutYear),
			};

			delete body.books;
			delete body.createdAt;
			delete body.lastLogin;
			delete body.role;
			delete body.id;

			api("users/" + this.id, "PATCH", true, body)
				.then(data => {
					this.user = data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.error = err;
					this.scrollToTop();
				});
		},

		copyId: function () {
			window.navigator.clipboard.writeText(this.user.id).catch(err => {
				console.log("Error writing to clipboard:", err);
			});
		},

		resetLogins: function () {
			this.user.failedLogins = 0;
			this.saveUser();
		},

		toggleActive: function () {
			this.user.isActive = !this.user.isActive;
			this.saveUser();
		},

		resetPassword: function () {
			this.error = false;
			this.resettingPassword = true;

			const body = {
				email: this.user.email,
			};

			api("auth/password-reset/request", "POST", false, body)
				.then(() => {
					this.resettingPassword = false;
				})
				.catch(err => {
					this.error = err;
					this.resettingPassword = false;
					this.scrollToTop();
				});
		},

		scrollToTop() {
			window.scroll(window.scrollX, 0);
		},
	},

	computed: {
		isActive() {
			return this.user.isActive;
		},

		isAuthor() {
			if (!this.user.roles) return false;
			return this.user.roles.includes(UserRole.AUTHOR);
		},

		isSelf() {
			return this.id === this.$store.getters.userId;
		},

		hasBooks() {
			return this.user.books && this.user.books.length > 0;
		},
	},

	watch: {
		userRole() {
			this.user.roles = this.userRole.map(role => role.value);
		},
		userLanguage() {
			this.user.language = this.userLanguage.value;
		},
	},

	created() {
		this.getUser();
	},
};
</script>

<style scoped lang="scss">
.heading-skeleton {
	height: 50px;
	max-width: 25ch;
}

.login-attempts-skeleton {
	max-width: 30ch;
}

.reset-logins-skeleton {
	width: 20ch;
	max-width: 20ch;
}

.activate-skeleton {
	width: 12ch;
	max-width: 12ch;
}

.submit-skeleton {
	width: 9ch;
	max-width: 9ch;
}
</style>
