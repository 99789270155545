<template>
	<b-nav-item-dropdown :text="$t('navbar.admin.dropdown')">
		<b-dropdown-item
			v-if="$store.getters.isAdmin"
			@click="navigator('AdminHome')"
		>
			{{ $t("navbar.admin.adminHome") }}
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
export default {
	name: "adminDropdown",
	methods: {
		navigator: function (page) {
			this.$router.push({ name: page }).catch(() => {});
		},
	},
};
</script>

<style scoped></style>
