<template>
	<div
		:class="this.chapter.public ? 'chapter-preview' : 'chapter-preview locked'"
		@click="click()"
	>
		<div class="d-flex justify-content-between align-items-center">
			<h5 class="mb-0">
				{{ $t("chapterPreview.chapterPrepend", { chapterNo: i + 1 }) }}:
				{{ chapter.name }}
			</h5>
			<b-icon icon="lock-fill" class="chapter-locked" v-if="!chapter.public" />
		</div>
		<p class="mb-0 mt-3 text-muted" v-if="chapter.summary">
			{{ chapter.summary }}
		</p>
	</div>
</template>

<script>
export default {
	name: "chapterPreview",
	props: {
		chapter: {
			type: Object,
			required: true,
		},
		i: {
			type: Number,
			required: true,
		},
	},
	methods: {
		click: function () {
			if (this.chapter.public) {
				this.$emit("click");
			}
		},
	},
};
</script>

<style scoped lang="scss">
.chapter-preview {
	width: 100%;
	height: fit-content;
	box-shadow: 0 0 5px 2.5px rgba(0, 0, 0, 0.1);
	padding: 15px;
	border-radius: 10px;
	cursor: pointer;
}

.locked {
	cursor: default;
	pointer-events: none;
}

.chapter-locked {
	font-size: 25px;
}
</style>
