<template>
	<b-alert
		show
		v-if="
			error &&
			error.message &&
			typeof error.message === 'object' &&
			error.message.length > 1
		"
		variant="danger"
	>
		<p>{{ $t("errorMessage.text") }}</p>
		<ul>
			<li v-for="message of error.message" :key="message">{{ message }}</li>
		</ul>
	</b-alert>
	<b-alert show v-else-if="error && error.message" variant="danger"
		>{{ error.message }}
	</b-alert>
	<b-alert show v-else-if="error" variant="danger">{{ error }}</b-alert>
</template>

<script>
export default {
	name: "errorMessage",
	props: ["error"],
};
</script>

<style scoped></style>
