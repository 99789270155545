<template>
	<div class="edit-book">
		<div class="container my-3 mx-auto row">
			<div class="col-3">
				<book-cover :src="book.coverPath" />
				<h2 class="mt-3">{{ book.title }}</h2>
			</div>
			<div class="col-9">
				<b-tabs content-class="mt-3" lazy>
					<b-tab :title="$t('author.edit.details.tab')">
						<BookDetails :bookProp="book" v-if="!initialLoad" />
					</b-tab>
					<b-tab :title="$t('author.edit.chapter.tab')">
						<BookChapters :chapters="book.chapters" v-if="!initialLoad" />
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import BookDetails from "@/views/Author/BookDetails.vue";
import BookChapters from "@/views/Author/BookChapters.vue";

export default {
	name: "EditBook",
	components: { BookChapters, BookDetails, BookCover },
	data() {
		return {
			id: this.$route.params.id,
			book: {},
			initialLoad: true,
			error: null,
		};
	},

	methods: {
		getBook() {
			api("books/" + this.id, "GET", true)
				.then(data => {
					this.book = data;

					if (data.description.length < 1) {
						this.book.description = this.$t(
							"author.edit.details.description.default"
						);
					}

					this.initialLoad = false;
					this.$log(data);
				})
				.catch(err => {
					this.error = err;
					this.initialLoad = false;
					this.$error(err);
				});
		},
	},

	created() {
		this.getBook();
	},
};
</script>

<style scoped></style>
