<template>
	<div class="reset-password">
		<div class="reset-password-wrapper p-3 my-3 rounded">
			<h1 class="mb-3">{{ $t("resetPassword.title") }}</h1>
			<b-form v-on:submit.prevent="reset">
				<b-input
					v-model="password"
					class="mb-2"
					:placeholder="$t('resetPassword.new')"
					type="password"
					required
					:state="passwordValid"
				/>
				<b-input
					v-model="passwordCheck"
					class="mb-2"
					:placholder="$t('resetPassword.repeat')"
					type="password"
					required
					:state="passwordCheckValid"
				/>
				<b-button
					variant="primary"
					class="w-100 shadow"
					type="submit"
					:disabled="!passwordValid || !passwordCheckValid || loading"
					>{{ $t("resetPassword.button") }}
				</b-button>
			</b-form>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";

export default {
	name: "ResetPassword",
	data() {
		return {
			token: this.$route.params.token,
			password: null,
			passwordCheck: null,
			loading: false,
		};
	},

	methods: {
		reset: function () {
			this.loading = true;
			api("auth/password-reset/" + this.token, "POST", false, {
				password: this.password,
				passwordCheck: this.passwordCheck,
			})
				.then(data => {
					this.loading = false;
					if (data.passwordReset) {
						this.$router.push({ name: "Login" });
					}
				})
				.catch(err => {
					this.loading = false;
					this.$log(err);
				});
		},
	},

	computed: {
		passwordValid() {
			if (typeof this.password !== "string") return null;
			return !!this.password.match(this.$store.state.passwordRegex);
		},

		passwordCheckValid() {
			if (typeof this.passwordCheck !== "string") return null;
			//if(this.passwordCheck !== this.password) return false
			return !!this.passwordCheck.match(this.$store.state.passwordRegex);
		},
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/styles";

.reset-password {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - $header-height);
	width: 100%;
}

.reset-password-wrapper {
	width: 300px;
	height: fit-content;
	background: $gray;
}
</style>
